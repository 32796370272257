@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-image: url("/fodno.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
      /* background-color: rgb(0 0 0 / 30%); */
    background-blend-mode: overlay;
}

@media (max-width: 1024px){
  body {
    background-image: url("/movil.jpg");
  }

  .board {
    height: 130% !important;
    width: 80% !important;
    
  }

  .butotom {
    width: 100%;
  }
}

@media (max-width: 820px){
  .board, .butotom  {
    max-width: 500px;
  }

  .board {
    top: 35% !important;
    height: 24% !important;
  }
}

@media (max-width: 768px){
  .board, .butotom {
    max-width: 422px;
  }
  .board {
    top: 35%; 
    height: 33% !important;
  }
}

.texto {
  font-family: 'Poppins', sans-serif;
  color: #3b497b;
  text-shadow: 2px 0 #fff;
}

.butotom {
  font-family: 'Poppins', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  display: grid;
  place-items: center;
  height: 100vh;
}
h1 {
  margin: 0;
}
.board {
  position: absolute;
  top: 15%;
  height: 61% ;
  width: 43.5%;
  padding-left: 1%;
}

@media(max-width: 1366px) and (max-height: 625px){
  .board {
    height: 58% !important;
  }
}
.tile {
  position: absolute;
  list-style: none;
  /* background: url("/logo.png"); */
  display: grid;
  place-items: center;
  font-size: 20px;
}

.restart {
  display: none;
  
}
button {
  display: block;
    background-color: transparent;
    width: 16%;
    height: 30%;
    border: none;
    box-shadow: none;
      max-width: 500px;
}

